/**
 * @Author: StephenChen
 * @Create Time: 2024-06-19 15:23:47
 * @Modified by: StephenChen
 * @Modified time: 2024-06-19 15:25:55
 * @Description: 地板
 */

import * as THREE from 'three'

export default class Floors {
  constructor(viewer) {
    this.viewer = viewer
    this.planeWidth = 500
    this.planeHeight = 500
    this._initFloor()
  }

  _initFloor() {
    const ground = new THREE.Mesh(
      new THREE.PlaneGeometry(this.planeWidth, this.planeHeight),
      new THREE.MeshPhongMaterial({ color: 0xbbbbbb, depthWrite: false })
    )
    ground.rotation.x = -Math.PI / 2
    ground.receiveShadow = true
    this.viewer.scene.add(ground)
  }

  /** 网格辅助线 */
  addGird(
    size = 500,
    divisions = 20,
    colorCenterLine = 0x888888,
    colorGrid = 0x888888
  ) {
    const grid = new THREE.GridHelper(
      size,
      divisions,
      colorCenterLine,
      colorGrid
    )
    this.viewer.scene.add(grid)
  }
}
