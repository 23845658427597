<!--
 * @Author: StephenChen
 * @Create Time: 2024-06-26 15:33:26
 * @Modified by: StephenChen
 * @Modified time: 2024-06-26 15:35:21
 * @Description: 三维模型组件弹出层
 -->

<template>
  <div
    class="popover"
    :style="{
      top: top + 'px',
      left: left + 'px',
      display: 'inline-block',
      backgroundColor: backgroundColor
    }"
  >
    <div class="popover-title" v-if="showTitle" v-html="title" />
    <el-divider v-if="showTitle && showContent" />
    <div class="popover-content" v-if="showContent" v-html="content" />
  </div>
</template>

<script>
export default {
  name: '3d-popover',
  props: {
    top: {
      type: Number,
      default: 0
    },
    left: {
      type: Number,
      default: 0
    },
    backgroundColor: {
      type: String,
      default: 'rgb(29 78 216 / 0.6)'
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ''
    },
    showContent: {
      type: Boolean,
      default: true
    },
    content: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.popover {
  position: absolute;
  border-radius: 5px;
  color: #fff;
}
.popover-title {
  padding: 12px;
}
.popover-content {
  padding: 18px;
}
.el-divider--horizontal {
  margin: 0;
}
</style>
