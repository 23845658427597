/**
 * @Author: StephenChen
 * @Create Time: 2024-06-19 14:39:37
 * @Modified by: StephenChen
 * @Modified time: 2024-06-19 15:34:15
 * @Description: 视图事件
 */

export default {
  animate: 'animate',
  dispose: 'dispose',
  orbitChange: 'orbitChange',
  load: {
    start: 'load:start',
    processing: 'load:processing',
    finish: 'load:finish'
  },
  click: {
    raycaster: 'click:raycaster'
  },
  dblclick: {
    raycaster: 'dblclick:raycaster'
  },
  mousemove: {
    raycaster: 'mousemove:raycaster'
  },
  resize: 'resize'
}
