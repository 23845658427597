<!--
 * @Author: StephenChen
 * @Create Time: 2024-06-19 14:17:21
 * @Modified by: StephenChen
 * @Modified time: 2024-06-26 15:34:26
 * @Description: 三维模型组件
 -->

<template>
  <div :class="`${KEY_COMPONENT_NAME}3d layout-3d`">
    <div id="three" :ref="id" />
    <Popover
      v-if="popover.show"
      :top="popover.top"
      :left="popover.left"
      :backgroundColor="popover.backgroundColor"
      :showTitle="popover.showTitle"
      :title="popover.title"
      :showContent="popover.showContent"
      :content="popover.content"
    />
  </div>
</template>

<script>
import * as THREE from 'three'
import { Viewer, ModelLoader, BoxHelperWrap, Event } from './core'
import { KEY_COMPONENT_NAME } from '../../echart/variable'
import Popover from './popover.vue'
import { checkNameEquals, findParent } from './utils'

export default {
  name: '3d',
  components: {
    Popover
  },
  props: {
    option: Object,
    component: Object
  },
  data() {
    return {
      KEY_COMPONENT_NAME,
      viewer: null,
      modelLoader: null,
      boxHelperWrap: null,
      models: [],
      popover: {
        show: false,
        top: 0,
        left: 0,
        backgroundColor: 'rgb(29 78 216 / 0.6)',
        showTitle: true,
        title: '',
        showContent: true,
        content: ''
      },
      hovers: []
    }
  },
  computed: {
    showFps() {
      return this.option.showFps
    },
    showAxis() {
      return this.option.showAxis
    },
    showSkyBox() {
      return this.option.showSkyBox
    },
    skyType() {
      return this.option.skyType
    },
    showPlane() {
      return this.option.showPlane
    },

    cameraFov() {
      return this.option.cameraFov
    },
    cameraAspect() {
      return this.option.cameraAspect
    },
    cameraNear() {
      return this.option.cameraNear
    },
    cameraFar() {
      return this.option.cameraFar
    },
    cameraScale() {
      return this.option.cameraScale
    },
    cameraPosition() {
      const { cameraPositionX, cameraPositionY, cameraPositionZ } = this.option
      return { x: cameraPositionX, y: cameraPositionY, z: cameraPositionZ }
    },
    cameraTarget() {
      const { cameraTargetX, cameraTargetY, cameraTargetZ } = this.option
      return { x: cameraTargetX, y: cameraTargetY, z: cameraTargetZ }
    },
    ambientLightColor() {
      let color = this.option.ambientLightColor
      return color
    },
    ambientLightIntensity() {
      return this.option.ambientLightIntensity
    },
    directionalLight() {
      return this.option.directionalLight
    }
  },
  watch: {
    showFps(val) {
      if (this.viewer) this.viewer[val ? 'addStats' : 'removeStats']()
    },
    showAxis(val) {
      if (this.viewer) this.viewer[val ? 'addAxis' : 'removeAxis']()
    },
    showSkyBox(val) {
      if (this.viewer) {
        val ? this.viewer.addSkyBox(this.skyType) : this.viewer.removeSkybox()
      }
    },
    skyType(val) {
      if (this.viewer) this.viewer.updateSkyboxType(val)
    },
    showPlane(val) {
      val ? this._addPlane() : this._removePlane()
    },
    cameraFov(val) {
      if (val != 0 && this.viewer && this.viewer.viewCamera) {
        this.viewer.viewCamera.fov = val
      }
    },
    cameraAspect(val) {
      if (this.viewer && this.viewer.viewCamera) {
        this.viewer.viewCamera.aspect = val
      }
    },
    cameraNear(val) {
      if (val != 0 && this.viewer && this.viewer.viewCamera) {
        this.viewer.viewCamera.near = val
      }
    },
    cameraFar(val) {
      if (this.viewer && this.viewer.viewCamera) {
        this.viewer.viewCamera.far = val
      }
    },
    cameraScale(val) {
      if (val != 0 && this.viewer && this.viewer.viewCamera) {
        this.viewer.viewCamera.zoom = val
      }
    },
    cameraPosition(val) {
      if (this.viewer && this.viewer.viewCamera) {
        this.viewer.viewCamera.position.set(val.x, val.y, val.z)
      }
    },
    cameraTarget(val) {
      if (this.viewer && this.viewer.viewCamera) {
        this.viewer.viewCamera.lookAt(val.x, val.y, val.z)
      }
    },
    ambientLightColor(val) {
      if (this.viewer) {
        this.viewer.updateAmbientLight(val, this.ambientLightIntensity)
      }
    },
    ambientLightIntensity(val) {
      if (this.viewer) {
        this.viewer.updateAmbientLight(this.ambientLightColor, val)
      }
    },
    directionalLight(val) {
      if (this.viewer) {
        this.viewer.updateDirectionalLight(val)
      }
    }
  },
  mounted() {
    this._initView()
  },
  methods: {
    updateChart() {
      const optionData = this.deepClone(this.dataChart)
      if (!this.modelLoader) return
      if (!this.models.length) {
        // 无加载模型，直接加载
        optionData.forEach(item => this.addModel(item, true))
      } else if (!optionData.length) {
        // 没有模型数据，移除所有模型
        this.modelLoader.removeModelsFromScene(this.models)
      } else {
        // 模型数据有变化，更新模型
        this.models.forEach(item => {
          const index = optionData.findIndex(i => item.id === (i.id || i.url))
          if (index < 0) {
            // 移除模型
            this.modelLoader.removeModelFromScene(item)
            this.models.splice(index, 1)
          }
        })
        // 添加或更新模型
        optionData.forEach(item => this.updateModel(item))
      }
    },

    _initView() {
      this.viewer = new Viewer('three')

      // 基础设置
      if (this.showFps) this.viewer.addStats()
      if (this.showAxis) this.viewer.addAxis()
      if (this.showSkyBox) this.viewer.addSkyBox(this.skyType)

      // 相机设置
      if (this.viewer.viewCamera) {
        if (this.cameraFov != 0) this.viewer.viewCamera.fov = this.cameraFov
        this.viewer.viewCamera.aspect = this.cameraAspect
        if (this.cameraNear != 0) this.viewer.viewCamera.near = this.cameraNear
        this.viewer.viewCamera.far = this.cameraFar
        this.viewer.viewCamera.zoom = this.cameraScale
        this.viewer.viewCamera.position.set(
          this.cameraPosition.x,
          this.cameraPosition.y,
          this.cameraPosition.z
        )
        this.viewer.viewCamera.lookAt(
          this.cameraTarget.x,
          this.cameraTarget.y,
          this.cameraTarget.z
        )
      }

      // 灯光设置
      if (this.ambientLightColor && this.ambientLightIntensity != 0) {
        this.viewer.updateAmbientLight(
          this.ambientLightColor,
          this.ambientLightIntensity
        )
      }
      this.viewer.updateDirectionalLight(this.directionalLight)

      // 模型加载器
      this.modelLoader = new ModelLoader(this.viewer)

      // 模型高亮
      this.boxHelperWrap = new BoxHelperWrap(this.viewer)

      // 注册鼠标事件监听
      this.viewer.initRaycaster()

      // 鼠标事件
      this.viewer.emitter.on(Event.dblclick.raycaster, list =>
        this._onMouseClick(list)
      )
      this.viewer.emitter.on(Event.mousemove.raycaster, list =>
        this._onMouseMove(list)
      )

      // 加载地板
      if (this.showPlane) this._addPlane()
    },

    // 加载或更新模型
    updateModel(cModel) {
      if (!this.modelLoader) return
      const { id, url, scale = 1, position = [], rotation = [], name } = cModel
      if (url && url.length) {
        const baseModel = this.models.find(model => model.id === (id || url))
        if (baseModel) {
          // 更新模型
          baseModel.setScalc(scale)
          baseModel.object.rotation.x = rotation[0] ?? 0
          baseModel.object.rotation.y = rotation[1] ?? 0
          baseModel.object.rotation.z = rotation[2] ?? 0
          const model = baseModel.gltf.scene
          model.position.set(
            position[0] ?? 0,
            position[1] ?? 0,
            position[2] ?? 0
          )
          model.name = name ?? ''
        } else {
          // 添加模型
          this.addModel(cModel, true)
        }
      }
    },

    // 加载模型
    addModel(cModel, record = false) {
      if (!this.modelLoader) return
      const {
        id,
        url,
        scale = 1,
        position = [],
        rotation = [],
        name,
        hover = []
      } = cModel

      if (url && url.length) {
        this.modelLoader.loadModelToScene(id || url, url, baseModel => {
          baseModel.setScalc(scale)
          baseModel.object.rotation.x = rotation[0] ?? 0
          baseModel.object.rotation.y = rotation[1] ?? 0
          baseModel.object.rotation.z = rotation[2] ?? 0
          const model = baseModel.gltf.scene

          model.position.set(
            position[0] ?? 0,
            position[1] ?? 0,
            position[2] ?? 0
          )
          model.name = name ?? ''
          baseModel.id = id || url
          baseModel.openCastShadow()

          if (hover.length) {
            this.setRaycaster(
              model,
              hover.map(item => item.name)
            )
            this.hovers = this.hovers.concat(hover)
          }

          if (record) this.models.push(baseModel)
        })
      }
    },

    // 根据 id 移除模型
    removeModelById(id = '') {
      if (!this.modelLoader || !id.length) return

      // 移除已记录模型
      const baseModel = this.models.find(model => model.id === id)
      if (baseModel) {
        this.modelLoader.removeModelFromScene(baseModel)
        this.models = this.models.filter(model => model.id !== id)
      }

      // 移除未记录模型
      const isRemove = this.modelLoader.removeModelFromSceneById(id)

      // true-表示移除，flase-表示未移除
      return !!baseModel || isRemove
    },

    // 加载地板
    _addPlane() {
      this.modelLoader.loadModelToScene(
        '__plane',
        '/3d/models/plane.glb',
        baseModel => {
          const model = baseModel.gltf.scene
          model.scale.set(0.0001 * 3, 0.0001 * 3, 0.0001 * 3)
          model.position.set(0, 0, 0)
          model.name = 'plane'
          baseModel.openCastShadow()

          const texture = baseModel.object.children[0].material.map
          const fnOnj = this._planeAnimate(texture)
          this.viewer.addAnimate(fnOnj)

          this.planeModel = baseModel
        }
      )
    },

    // 移除地板
    _removePlane() {
      if (!this.modelLoader || !this.planeModel) return
      this.modelLoader.removeModelFromScene(this.planeModel)
    },

    // 地板动画
    _planeAnimate(texture) {
      texture.wrapS = THREE.RepeatWrapping
      texture.wrapT = THREE.RepeatWrapping
      const animateFn = {
        fun: () => {
          const count = texture.repeat.y
          if (count <= 10) {
            texture.repeat.x += 0.01
            texture.repeat.y += 0.02
          } else {
            texture.repeat.x = 0
            texture.repeat.y = 0
          }
        },
        content: this.viewer
      }
      return animateFn
    },

    // 注册鼠标事件监听
    setRaycaster(model, names = []) {
      if (!names.length || !model) return
      const boxList = []
      model.traverse(item => {
        names.forEach(name => {
          if (checkNameEquals(item, name)) boxList.push(item)
        })
      })
      this.viewer.setRaycasterObjects(boxList)
    },

    // 鼠标点击
    _onMouseClick(intersects) {
      this.clickFormatter &&
        this.clickFormatter(
          { data: this.dataChart, intersects },
          this.getItemRefs()
        )
    },

    // 鼠标滑动
    _onMouseMove(intersects) {
      if (this.moveFormatter) {
        const res = this.moveFormatter(
          { data: this.dataChart, intersects },
          this.getItemRefs()
        )
        if (res) {
          return
        }
      }

      if (!intersects.length) {
        this.popover.show = false
        this.boxHelperWrap.setVisible(false)
        return
      }

      let names = []
      this.hovers.forEach(hover => {
        if (hover.name && hover.name.length) {
          names.push(hover.name)
        }
      })
      names = [...new Set(names)]
      if (!names.length) return

      const currentObject = intersects[0].object || {}
      const { box, name } = this.findBoxByName(currentObject, names)
      if (box) {
        this.boxHelperWrap.attach(box)
        const hover = this.hovers.find(item => item.name === name)
        this._updatePopoverInfo(hover)
      }
    },

    /** 根据名称查询当前激活对象 */
    findBoxByName(object, names) {
      if (!object || !names || !names.length) return {}
      const currentObjectName = this._findClickModelByName(object, names)
      if (!currentObjectName || !currentObjectName.length) return {}
      return {
        box: findParent(object, obj => checkNameEquals(obj, currentObjectName)),
        name: currentObjectName
      }
    },

    _findClickModelByName(object, names) {
      let currentObjectName = ''
      if (!object || !names || !names.length) return ''
      const findClickModel = object => {
        for (const index in names) {
          const name = names[index]
          if (checkNameEquals(object, name)) {
            currentObjectName = name
            break
          }
        }
        if (currentObjectName.length) return
        if (object.parent) findClickModel(object.parent)
      }
      findClickModel(object)
      return currentObjectName
    },

    _updatePopoverInfo(hover) {
      if (hover) {
        const defaultPopover = {
          show: false,
          backgroundColor: 'rgb(29 78 216 / 0.6)',
          showTitle: true,
          title: '',
          showContent: true,
          content: ''
        }
        this.popover = { ...defaultPopover, ...hover }
        const event = this.viewer.mouseEvent
        this.popover.left = event.offsetX + 20
        this.popover.top = event.offsetY + 20
        this.popover.show = true
      } else {
        this.popover.show = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.layout-3d {
  width: 100%;
  height: 100%;
}
#three {
  height: 100%;
  width: 100%;
}
</style>
