<template>
  <el-dialog
    title="图库"
    width="80%"
    :close-on-click-modal="false"
    :visible.sync="imgVisible"
  >
    <div style="margin: 0 auto">
      <el-upload
        class="upload-demo"
        :on-success="onSuccess"
        :show-file-list="false"
        :action="url + '/visual/put-file'"
        :headers="uploadHeaders"
        multiple
        list-type="picture"
      >
        <el-button size="small" icon="el-icon-upload" type="primary">
          点击上传
        </el-button>
      </el-upload>
    </div>
    <el-scrollbar class="imgList">
      <img
        :src="item.value"
        :style="styleName"
        @click="handleSetimg(item.value)"
        v-for="(item, index) in imgOption[imgActive]"
        :key="index"
      />
    </el-scrollbar>
  </el-dialog>
</template>

<script>
import { url } from '@/config'
import { imgOption } from '@/option/config'
import { getToken } from '@/utils/auth'
import { Base64 } from 'js-base64'
import { website } from '@/config.js'

export default {
  data() {
    return {
      uploadHeaders: {},
      url: url,
      imgVisible: false,
      imgObj: '',
      type: '',
      imgActive: 0,
      imgOption: imgOption,
      imgTabs: []
    }
  },
  computed: {
    styleName() {
      if (this.type === 'background') {
        return {
          width: '200px'
        }
      }
      return {}
    }
  },
  watch: {
    type: {
      handler() {
        if (this.type === 'background') {
          this.imgActive = 0
        } else if (this.type == 'border') {
          this.imgActive = 1
        } else {
          this.imgActive = 2
        }
      },
      immediate: true
    }
  },
  created() {
    this.initUploadHeaders()
  },
  methods: {
    initUploadHeaders() {
      this.uploadHeaders['Authorization'] = `Basic ${Base64.encode(
        `${website.clientId}:${website.clientSecret}`
      )}`
      if (getToken()) {
        this.uploadHeaders[website.tokenHeader] = 'bearer ' + getToken()
      }
    },
    onSuccess(res) {
      const url = res.data.link
      this.imgOption[this.imgActive].unshift({
        label: url,
        value: url
      })
    },
    openImg(item, type) {
      this.type = type
      this.imgObj = item
      this.imgVisible = true
    },
    handleSetimg(item) {
      this.imgVisible = false
      this.$emit('change', item, this.imgObj)
    }
  }
}
</script>

<style></style>
