<template>
  <div class="index">
    <div class="header">
      <img
        :src="`${$router.options.base}img/nav-img.png`"
        alt=""
        width="100%"
        height="220px"
      />
      <div class="title">
        <p>
          {{ $website.name }}
          <br />
          <small>{{ $website.subName }}</small>
        </p>
      </div>
      <navs @change="handleChange"></navs>
      <div class="logou-button">
        <el-button type="primary" size="small" @click="handleLogout">
          退出登录
        </el-button>
      </div>
    </div>
    <div class="main" v-if="activeName == 0">
      <list></list>
    </div>
    <el-scrollbar class="main">
      <maps v-if="activeName == 1"></maps>
      <category v-else-if="activeName == 2"></category>
      <db v-else-if="activeName == 3"></db>
      <document v-else-if="activeName == 4"></document>
    </el-scrollbar>
  </div>
</template>
<script>
import list from './list/index'
import maps from './list/map'
import category from './list/category'
import db from './list/db'
import document from './list/document'
import navs from './nav/index'
import { validatenull } from '@/utils/validate'
import { calcDate } from '@/utils/date.js'
import { getStore } from '@/utils/store.js'
export default {
  name: 'index',
  components: {
    navs,
    list,
    maps,
    category,
    db,
    document
  },
  data() {
    return {
      activeName: 0
    }
  },
  created() {
    //实时检测刷新token
    this.refreshToken()
  },
  methods: {
    handleChange(nav, index) {
      this.activeName = index
    },
    handleLogout() {
      this.$confirm('确定要退出登录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$store.dispatch('FedLogOut').then(() => {
            this.$router.push({ path: '/login' })
          })
        })
        .catch(() => {})
    },
    // 定时检测token
    refreshToken() {
      this.refreshTime = setInterval(() => {
        const token =
          getStore({
            name: 'token',
            debug: true
          }) || {}
        const date = calcDate(token.datetime, new Date().getTime())
        if (validatenull(date)) return
        if (date.seconds >= this.$website.tokenTime && !this.refreshLock) {
          this.refreshLock = true
          this.$store
            .dispatch('refreshToken')
            .then(() => {
              this.refreshLock = false
            })
            .catch(() => {
              this.refreshLock = false
            })
        }
      }, 10000)
    }
  }
}
</script>
<style lang="scss">
.index {
  height: 100%;
  .header {
    position: relative;
    .nav {
      margin: 0 20px;
      width: 100%;
      position: absolute;
      bottom: 10px;
      border: none;
      .el-menu-item {
        background-color: rgba(0, 0, 0, 0) !important;
      }
    }
    .title {
      position: absolute;
      top: 60px;
      left: 20px;
      font-size: 48px;
      font-style: oblique;
      color: rgb(0, 186, 255);
      font-weight: bold;
      line-height: 35px;
    }
    .title small {
      font-size: 18px;
      color: #9cb4c2;
    }
    .logou-button {
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }
  .main {
    width: 100%;
    height: calc(100% - 220px);
  }
}
</style>
