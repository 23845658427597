<template>
  <div class="document">
    <div class="item">
      <p class="avue-tip-title">大屏轮播</p>
      <el-input placeholder="请输入大屏ID多个大屏用','间隔" v-model="value1">
        <span slot="append" @click="goMenu2">预览大屏</span>
      </el-input>
    </div>
    <div class="item">
      <p class="avue-tip-title">HTML页面</p>
      <el-input placeholder="请输入大屏ID" v-model="value">
        <span slot="append" @click="goMenu1">预览大屏</span>
      </el-input>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: '1',
      value1: '1,2,5,6',
    };
  },
  methods: {
    goMenu1() {
      window.open('/view.html?id=' + this.value);
    },
    goMenu2() {
      window.open('/swiper.html?id=' + this.value1);
    },
  },
};
</script>

<style lang="scss">
.document {
  padding: 0 30px;
  .item {
    margin-bottom: 20px;
  }
  .avue-tip-title {
    font-size: 18px;
    margin-bottom: 5px;
  }
}
</style>
