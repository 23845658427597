<template>
  <div id="amap-container" style="width: 100%; height: 100%" />
</template>
<script>
import AMapLoader from '@amap/amap-jsapi-loader'

export default {
  name: 'amap',
  props: {
    option: Object,
    component: Object
  },
  data() {
    return {
      AMap: null,
      map: null,
      infoWindow: null
    }
  },
  watch: {
    option: {
      handler() {
        this._initAMap()
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    this._initAMap()
  },
  unmounted() {
    this.map?.destroy()
    this.AMap = null
    this.map = null
    this.infoWindow = null
  },
  methods: {
    _initAMap() {
      const {
        securityJsCode,
        amapKey,
        toolBar,
        toolBarTop,
        toolBarBottom,
        toolBarLeft,
        toolBarRight,
        scale,
        hawkEye,
        controlBar,
        controlBarTop,
        controlBarBottom,
        controlBarLeft,
        controlBarRight,
        rotateEnable,
        pitchEnable,
        is3D,
        terrain,
        zoom,
        zoomMini,
        zoomMax,
        pitch,
        rotation,
        centerLongitude,
        centerLatitude,
        amapStyle
      } = this.option || {}

      window._AMapSecurityConfig = { securityJsCode }

      const plugins = []
      if (this._isAreaMask()) plugins.push('AMap.DistrictSearch')
      if (toolBar) plugins.push('AMap.ToolBar')
      if (scale) plugins.push('AMap.Scale')
      if (hawkEye) plugins.push('AMap.HawkEye')
      if (controlBar) plugins.push('AMap.ControlBar')

      AMapLoader.load({ key: amapKey, version: '2.0', plugins })
        .then(async AMap => {
          this.AMap = AMap

          const mapOpts = {
            rotateEnable,
            pitchEnable,
            terrain,
            zoom: zoom || 16,
            pitch: pitch || 50,
            rotation: rotation || -15,
            viewMode: is3D ? '3D' : null,
            zooms: [zoomMini || 2, zoomMax || 20],
            center: [centerLongitude || 116.333926, centerLatitude || 39.997245],
            mapStyle: amapStyle || 'amap://styles/blue'
          }

          let bounds = null
          if (this._isAreaMask()) {
            const mask = []
            bounds = await this._getDistrictBounds(AMap)
            for (var i = 0; i < bounds.length; i += 1) {
              mask.push([bounds[i]])
            }
            mapOpts.mask = mask
          }

          this.map = new AMap.Map('amap-container', mapOpts)
          if (this._isAreaMask()) this._setPolyline(AMap, bounds, this.map)
          if (scale) this.map.addControl(new AMap.Scale())
          if (hawkEye) this.map.addControl(new AMap.HawkEye())
          if (toolBar)
            this.map.addControl(
              new AMap.ToolBar({
                position: {
                  top: toolBarTop ? `${toolBarTop}px` : null,
                  bottom: toolBarBottom ? `${toolBarBottom}px` : null,
                  left: toolBarLeft ? `${toolBarLeft}px` : null,
                  right: toolBarRight ? `${toolBarRight}px` : null
                }
              })
            )
          if (controlBar)
            this.map.addControl(
              new AMap.ControlBar({
                position: {
                  top: controlBarTop ? `${controlBarTop}px` : null,
                  bottom: controlBarBottom ? `${controlBarBottom}px` : null,
                  left: controlBarLeft ? `${controlBarLeft}px` : null,
                  right: controlBarRight ? `${controlBarRight}px` : null
                }
              })
            )
        })
        .catch(e => {
          console.log(e)
        })
    },

    _isAreaMask() {
      const { areaName, areaLevel } = this.option || {}
      return areaName && areaName.length && areaLevel && areaLevel.length
    },
    _getDistrictBounds(AMap) {
      return new Promise(resolve => {
        if (!this._isAreaMask()) return resolve([])
        const { areaName, areaLevel } = this.option || {}
        const districtOpts = { subdistrict: 0, extensions: 'all', level: areaLevel }
        const district = new AMap.DistrictSearch(districtOpts)
        district.search(areaName, (status, result) => {
          const bounds = result.districtList[0].boundaries
          resolve(bounds)
        })
      })
    },
    _setPolyline(AMap, bounds, map) {
      for (let i = 0; i < bounds.length; i += 1) {
        new AMap.Polyline({
          path: bounds[i],
          strokeColor: this.option.polylineColor || '#99ffff',
          strokeWeight: this.option.polylineWeight || 4,
          map: map
        })
      }
    },

    getMap() {
      return this.map
    },

    openInfoWindow({ content = [], position, anchor = 'bottom-center', isCustom = false }) {
      if (!this.AMap || content.length === 0) return
      this.closeInfoWindow()
      this.infoWindow = new this.AMap.InfoWindow({
        isCustom,
        content: content.join('<br>'),
        anchor: anchor
      })
      this.infoWindow.open(this.map, position)
    },
    closeInfoWindow() {
      this.infoWindow?.close()
    }
  }
}
</script>

<style scoped></style>
