/**
 * @Author: StephenChen
 * @Create Time: 2024-06-19 15:18:05
 * @Modified by: StephenChen
 * @Modified time: 2024-06-19 15:18:32
 * @Description: 模型加载器
 */

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'
import BaseModel from '../baseModel'

export default class ModelLoder {
  constructor(viewer, dracolPath = '/3d/draco/') {
    this.viewer = viewer
    this.dracoLoader = new DRACOLoader()
    this.gltfLoader = new GLTFLoader()

    // 提供一个DracLoader实例来解码压缩网格数据
    // 没有这个会报错 dracolPath 默认放在public文件夹当中
    this.dracoLoader.setDecoderPath(dracolPath)
    this.gltfLoader.setDRACOLoader(this.dracoLoader)
  }

  /** 模型加载到场景 */
  loadModelToScene(id, url, callback) {
    this._loadModel(url, model => {
      model.object.mId = id || url
      this.viewer.scene.add(model.object)
      callback && callback(model)
    })
  }

  /** 加载模型 */
  _loadModel(url, callback) {
    this.gltfLoader.load(url, gltf => {
      const baseModel = new BaseModel(gltf, this.viewer)
      callback && callback(baseModel)
    })
  }

  /** 移除模型 */
  removeModelFromSceneById(id) {
    if (!id || !id.length) return false

    const children = this.viewer.scene.children || []
    if (!children.length) return false

    const model = children.find(child => child.mId === id)
    if (!model) return false

    this.viewer.scene.remove(model)
    return true
  }

  /** 移除模型 */
  removeModelFromScene(model) {
    if (model.object) {
      this.viewer.scene.remove(model.object)
    }
  }

  /** 移除多个模型 */
  removeModelsFromScene(models = []) {
    models.forEach(model => {
      this.removeModelFromScene(model)
    })
  }
}
