<template>
  <div>
    <el-form-item label="安全密钥">
      <avue-input v-model="main.activeOption.securityJsCode" />
    </el-form-item>
    <el-form-item label="Key">
      <avue-input v-model="main.activeOption.amapKey" />
    </el-form-item>

    <el-divider />

    <el-form-item label="区域级别">
      <avue-select
        v-model="main.activeOption.areaLevel"
        placeholder="请选择区域级别"
        :dic="[
          { label: '国家', value: 'country' },
          { label: '省/直辖市', value: 'province' },
          { label: '市', value: 'city' },
          { label: '区/县', value: 'district' },
          { label: '商圈', value: 'biz_area' }
        ]"
      ></avue-select>
    </el-form-item>
    <el-form-item label="区域名称">
      <avue-input v-model="main.activeOption.areaName" />
    </el-form-item>
    <el-form-item label="描边颜色">
      <avue-input-color v-model="main.activeOption.polylineColor" />
    </el-form-item>
    <el-form-item label="描边大小">
      <avue-input-number v-model="main.activeOption.polylineWeight" />
    </el-form-item>

    <el-divider />

    <el-form-item label="比例尺">
      <avue-switch v-model="main.activeOption.scale"></avue-switch>
    </el-form-item>
    <el-form-item label="小地图">
      <avue-switch v-model="main.activeOption.hawkEye"></avue-switch>
    </el-form-item>

    <el-form-item label="旋转按钮">
      <avue-switch v-model="main.activeOption.controlBar"></avue-switch>
    </el-form-item>
    <el-row v-if="main.activeOption.controlBar">
      <el-col :span="12">
        <el-form-item label="上" labelWidth="50px">
          <avue-input-number v-model="main.activeOption.controlBarTop" />
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="下" labelWidth="50px">
          <avue-input-number v-model="main.activeOption.controlBarBottom" />
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="左" labelWidth="50px">
          <avue-input-number v-model="main.activeOption.controlBarLeft" />
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="右" labelWidth="50px">
          <avue-input-number v-model="main.activeOption.controlBarRight" />
        </el-form-item>
      </el-col>
    </el-row>

    <el-form-item label="缩放按钮">
      <avue-switch v-model="main.activeOption.toolBar"></avue-switch>
    </el-form-item>
    <el-row v-if="main.activeOption.toolBar">
      <el-col :span="12">
        <el-form-item label="上" labelWidth="50px">
          <avue-input-number v-model="main.activeOption.toolBarTop" />
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="下" labelWidth="50px">
          <avue-input-number v-model="main.activeOption.toolBarBottom" />
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="左" labelWidth="50px">
          <avue-input-number v-model="main.activeOption.toolBarLeft" />
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="右" labelWidth="50px">
          <avue-input-number v-model="main.activeOption.toolBarRight" />
        </el-form-item>
      </el-col>
    </el-row>

    <el-form-item label="地图旋转交互">
      <avue-switch v-model="main.activeOption.rotateEnable"></avue-switch>
    </el-form-item>
    <el-form-item label="地图倾斜交互">
      <avue-switch v-model="main.activeOption.pitchEnable"></avue-switch>
    </el-form-item>
    <el-form-item label="3D">
      <avue-switch v-model="main.activeOption.is3D"></avue-switch>
    </el-form-item>
    <el-form-item label="开启地形图">
      <avue-switch v-model="main.activeOption.terrain"></avue-switch>
    </el-form-item>

    <el-divider />

    <el-form-item label="地图缩放层级">
      <avue-input-number v-model="main.activeOption.zoom" />
    </el-form-item>
    <el-form-item label="缩放级别范围：最小" labelWidth="150px">
      <avue-input-number v-model="main.activeOption.zoomMini" />
    </el-form-item>
    <el-form-item label="缩放级别范围：最大" labelWidth="150px">
      <avue-input-number v-model="main.activeOption.zoomMax" />
    </el-form-item>
    <el-form-item label="地图俯仰角度(0-83)" labelWidth="150px">
      <avue-input-number v-model="main.activeOption.pitch" />
    </el-form-item>
    <el-form-item label="地图旋转角度">
      <avue-input-number v-model="main.activeOption.rotation" />
    </el-form-item>
    <el-form-item label="地图中心：经度">
      <avue-input-number v-model="main.activeOption.centerLongitude" />
    </el-form-item>
    <el-form-item label="地图中心：纬度">
      <avue-input-number v-model="main.activeOption.centerLatitude" />
    </el-form-item>
    <el-form-item label="地图主题">
      <avue-select
        v-model="main.activeOption.amapStyle"
        placeholder="请选择地图主题"
        :clearable="false"
        :dic="[
          { label: '标准', value: 'amap://styles/normal' },
          { label: '幻影黑', value: 'amap://styles/dark' },
          { label: '月光银', value: 'amap://styles/light' },
          { label: '远山黛', value: 'amap://styles/whitesmoke' },
          { label: '草色青', value: 'amap://styles/fresh' },
          { label: '雅士灰', value: 'amap://styles/grey' },
          { label: '涂鸦', value: 'amap://styles/graffiti' },
          { label: '马卡龙', value: 'amap://styles/macaron' },
          { label: '靛青蓝', value: 'amap://styles/blue' },
          { label: '极夜蓝', value: 'amap://styles/darkblue' },
          { label: '酱籽', value: 'amap://styles/wine' }
        ]"
      ></avue-select>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: 'amap',
  inject: ['main'],
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss" scoped></style>
