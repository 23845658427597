/**
 * @Author: StephenChen
 * @Create Time: 2024-06-19 15:26:33
 * @Modified by: StephenChen
 * @Modified time: 2024-06-19 15:28:42
 * @Description: 盒子轮廓辅助线
 */

import { BoxHelper, Color, Object3D } from 'three'

export default class BoxHelperWrap {
  constructor(viewer, color) {
    this.viewer = viewer
    const boxColor = color === undefined ? 0x00ffff : color
    this.boxHelper = new BoxHelper(new Object3D(), new Color(boxColor))
    this._initBoxHelperWrap()
  }

  _initBoxHelperWrap() {
    this.viewer.scene.add(this.boxHelper)
  }

  setVisible(visible) {
    this.boxHelper.visible = visible
  }

  attach(obj) {
    this.boxHelper.setFromObject(obj)
    this.setVisible(true)
  }

  dispose() {
    const parent = this.boxHelper.parent
    if (parent !== null) parent.remove(this.boxHelper)
    Object.keys(this).forEach(key => (this[key] = null))
  }
}
