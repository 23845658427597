<template>
  <div class="login-container" ref="login" @keyup.enter.native="handleLogin">
    <div class="login-weaper animated bounceInDown">
      <div class="login-left">
        <div class="login-time">
          {{ time }}
        </div>
        <p class="title">{{ title }}</p>
        <img class="img" src="/img/logo.png" alt="" />
      </div>
      <div class="login-border">
        <div class="login-main">
          <h4 class="login-title">账户绑定</h4>
          <userLogin></userLogin>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import userLogin from './userlogin'
import { dateFormat } from '@/utils/date'
import { validatenull } from '@/utils/validate'
import { getQueryString, getTopUrl } from '@/utils/utils'
import { website } from '@/config.js'

export default {
  name: 'login',
  components: { userLogin },
  data() {
    return {
      title: website.title,
      time: '',
      socialForm: {
        tenantId: '000000',
        source: '',
        code: '',
        state: ''
      }
    }
  },
  watch: {
    $route() {
      this.handleLogin()
    }
  },
  created() {
    this.handleLogin()
    this.getTime()
  },
  mounted() {},
  props: [],
  methods: {
    getTime() {
      setInterval(() => {
        this.time = dateFormat(new Date())
      }, 1000)
    },
    handleLogin() {
      const topUrl = getTopUrl()
      const redirectUrl = '/oauth/redirect/'
      this.socialForm.source = getQueryString('source')
      this.socialForm.code = getQueryString('code')
      this.socialForm.state = getQueryString('state')
      if (
        validatenull(this.socialForm.source) &&
        topUrl.includes(redirectUrl)
      ) {
        let source = topUrl.split('?')[0]
        source = source.split(redirectUrl)[1]
        this.socialForm.source = source
      }
      if (
        !validatenull(this.socialForm.source) &&
        !validatenull(this.socialForm.code) &&
        !validatenull(this.socialForm.state)
      ) {
        const loading = this.$loading({
          lock: true,
          text: '第三方系统登录中,请稍后。。。',
          spinner: 'el-icon-loading'
        })
        this.$store
          .dispatch('LoginBySocial', this.socialForm)
          .then(() => {
            window.location.href = topUrl.split(redirectUrl)[0]
            this.$router.push({ path: '/' })
            loading.close()
          })
          .catch(() => {
            loading.close()
          })
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/login.scss';
</style>
