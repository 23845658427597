import request from '@/axios'

export const tenantInfo = domain => {
  return request({
    url: '/api/blade-system/tenant/info',
    method: 'get',
    params: {
      domain
    }
  })
}
