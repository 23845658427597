/**
 * @Author: StephenChen
 * @Create Time: 2024-06-19 15:21:09
 * @Modified by: StephenChen
 * @Modified time: 2024-06-19 15:21:33
 * @Description: 平行光
 */

import * as THREE from 'three'

export default class DirectionalLight {
  constructor(viewer) {
    this.viewer = viewer
    const ambient = new THREE.AmbientLight(0xffffff, 0.4)
    this.viewer.scene.add(ambient)
    this.light = new THREE.DirectionalLight(new THREE.Color('#fff'))
    this.viewer.scene.add(this.light)
  }
}
