<!--
 * @Author: StephenChen
 * @Create Time: 2024-06-19 14:18:26
 * @Modified by: StephenChen
 * @Modified time: 2024-06-26 15:35:01
 * @Description: 三维模型组件配置
 -->

<template>
  <div>
    <el-form-item label="FPS">
      <avue-switch v-model="main.activeOption.showFps"></avue-switch>
    </el-form-item>
    <el-form-item label="辅助坐标">
      <avue-switch v-model="main.activeOption.showAxis"></avue-switch>
    </el-form-item>
    <el-form-item label="天空盒子">
      <avue-switch v-model="main.activeOption.showSkyBox"></avue-switch>
    </el-form-item>
    <el-form-item label="天空类型" v-if="main.activeOption.showSkyBox">
      <avue-select
        v-model="main.activeOption.skyType"
        placeholder="请选择天空类型"
        :clearable="false"
        :dic="[
          { label: '白天', value: 'daytime' },
          { label: '黄昏', value: 'dusk' },
          { label: '夜晚', value: 'night' }
        ]"
      ></avue-select>
    </el-form-item>
    <el-form-item label="地板">
      <avue-switch v-model="main.activeOption.showPlane"></avue-switch>
    </el-form-item>

    <el-collapse accordion>
      <!-- 相机设置 -->
      <el-collapse-item title="视锥范围">
        <el-form-item label="视野范围(fov)">
          <avue-input-number v-model="main.activeOption.cameraFov" />
        </el-form-item>
        <el-form-item label="长宽比(aspect)">
          <avue-input-number v-model="main.activeOption.cameraAspect" />
        </el-form-item>
        <el-form-item label="近平面(near)">
          <avue-input-number v-model="main.activeOption.cameraNear" />
        </el-form-item>
        <el-form-item label="远平面(far)">
          <avue-input-number v-model="main.activeOption.cameraFar" />
        </el-form-item>
      </el-collapse-item>

      <el-collapse-item title="相机缩放">
        <el-form-item label="缩放倍数">
          <avue-input-number v-model="main.activeOption.cameraScale" />
        </el-form-item>
      </el-collapse-item>

      <el-collapse-item title="相机位置">
        <el-form-item label="x">
          <avue-input-number v-model="main.activeOption.cameraPositionX" />
        </el-form-item>
        <el-form-item label="y">
          <avue-input-number v-model="main.activeOption.cameraPositionY" />
        </el-form-item>
        <el-form-item label="z">
          <avue-input-number v-model="main.activeOption.cameraPositionZ" />
        </el-form-item>
      </el-collapse-item>

      <el-collapse-item title="相机方向">
        <el-form-item label="x">
          <avue-input-number v-model="main.activeOption.cameraTargetX" />
        </el-form-item>
        <el-form-item label="y">
          <avue-input-number v-model="main.activeOption.cameraTargetY" />
        </el-form-item>
        <el-form-item label="z">
          <avue-input-number v-model="main.activeOption.cameraTargetZ" />
        </el-form-item>
      </el-collapse-item>

      <!-- 灯光设置 -->
      <el-collapse-item title="环境光">
        <el-form-item label="颜色">
          <avue-input-color
            :show-alpha="false"
            v-model="main.activeOption.ambientLightColor"
          />
        </el-form-item>
        <el-form-item label="强度">
          <avue-input-number
            v-model="main.activeOption.ambientLightIntensity"
          />
        </el-form-item>
      </el-collapse-item>

      <el-collapse-item title="平行光">
        <avue-crud
          :option="directionalLightOption"
          :data="main.activeOption.directionalLight"
          @row-save="rowSave"
          @row-del="rowDel"
          @row-update="rowUpdate"
        ></avue-crud>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
export default {
  name: '3d',
  inject: ['main'],
  data() {
    return {
      directionalLightOption: {
        menuWidth: 150,
        refreshBtn: false,
        columnBtn: false,
        labelWidth: 100,
        column: [
          {
            label: 'x',
            prop: 'x',
            type: 'number',
            width: 45
          },
          {
            label: 'y',
            prop: 'y',
            type: 'number',
            width: 45
          },
          {
            label: 'z',
            prop: 'z',
            type: 'number',
            width: 45
          },
          {
            label: '颜色',
            prop: 'color',
            type: 'color',
            showAlpha: false,
            width: 50
          }
        ]
      }
    }
  },
  methods: {
    rowSave(row, done) {
      this.main.activeOption.directionalLight.push(this.deepClone(row))
      done()
    },
    rowDel(row, index) {
      this.main.activeOption.directionalLight.splice(index, 1)
    },
    rowUpdate(row, index, done) {
      this.main.activeOption.directionalLight.splice(
        index,
        1,
        this.deepClone(row)
      )
      done()
    }
  }
}
</script>

<style></style>
