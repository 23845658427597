/**
 * @Author: StephenChen
 * @Create Time: 2024-06-19 15:21:15
 * @Modified by: StephenChen
 * @Modified time: 2024-06-19 15:22:36
 * @Description: 环境光
 */

import { AmbientLight } from 'three'

export default class Lights {
  constructor(viewer) {
    this.viewer = viewer
    const ambient = new AmbientLight(0xffffff, 0.4)
    this.viewer.scene.add(ambient)
  }
}
