/**
 * @Author: StephenChen
 * @Create Time: 2024-06-19 15:11:44
 * @Modified by: StephenChen
 * @Modified time: 2024-06-19 15:12:24
 * @Description: 场景天空盒
 */

import * as THREE from 'three'

export default class SkyBoxs {
  constructor(viewer) {
    this.viewer = viewer
  }

  /** 添加雾效果 */
  addFog(color = 0xa0a0a0, near = 500, far = 2000) {
    this.viewer.scene.fog = new THREE.Fog(new THREE.Color(color), near, far)
  }

  /** 移除雾效果 */
  removeFog() {
    this.viewer.scene.fog = null
  }

  /** 添加默认天空盒 白天-daytime 黄昏-dusk 夜晚-night */
  addSkybox(skyType = 'night') {
    const path = `/3d/skybox/${skyType}/` // 设置路径
    const format = '.jpg' // 设定格式
    this.setSkybox(path, format)
  }

  /** 移除默认天空盒 */
  removeSkybox() {
    this.viewer.scene.background = null
  }

  /** 自定义添加天空盒 */
  setSkybox(path, format = '.jpg') {
    const loaderbox = new THREE.CubeTextureLoader()
    const cubeTexture = loaderbox.load([
      path + 'posx' + format,
      path + 'negx' + format,
      path + 'posy' + format,
      path + 'negy' + format,
      path + 'posz' + format,
      path + 'negz' + format
    ])
    // 需要把色彩空间编码改一下
    cubeTexture.encoding = THREE.sRGBEncoding
    this.viewer.scene.background = cubeTexture
  }

  /** 更新天空盒 */
  updateSkyboxType(skyType = 'night') {
    this.removeSkybox()
    this.addSkybox(skyType)
  }
}
