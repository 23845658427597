/**
 * @Author: StephenChen
 * @Create Time: 2024-06-19 14:39:27
 * @Modified by: StephenChen
 * @Modified time: 2024-06-19 15:16:39
 * @Description: 场景视图
 */

import * as THREE from 'three'
import {
  Scene,
  PerspectiveCamera,
  AxesHelper,
  WebGLRenderer,
  SRGBColorSpace,
  AmbientLight,
  Raycaster,
  Vector2
} from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import Stats from 'three/examples/jsm/libs/stats.module'
import mitt from 'mitt'
import { throttle } from 'lodash-es'
import Events from './events'
import SkyBoxs from '../skyBoxs'

export default class Viewer {
  constructor(id) {
    this.id = id
    this.animateEventList = []
    this.raycasterObjects = []
    this.isDestroy = false

    this._initViewer()
  }

  /** 坐标轴辅助 */
  addAxis() {
    if (!this.axis) this.axis = new AxesHelper(1000)
    this.scene?.add(this.axis)
  }

  /** 移除轴辅助 */
  removeAxis() {
    if (this.axis) this.scene?.remove(this.axis)
  }

  /** 添加动画 */
  addAnimate(animate) {
    this.animateEventList.push(animate)
  }

  /** 添加性能状态监测 */
  addStats() {
    if (!this.statsControls) this.statsControls = new Stats()
    this.statsControls.dom.style.position = 'absolute'
    this.viewerDom.appendChild(this.statsControls.dom)

    // 添加到动画
    this.addAnimate({
      fun: this._statsUpdate,
      content: this.statsControls
    })
  }

  /** 移除性能状态监测 */
  removeStats() {
    if (this.statsControls) {
      this.viewerDom.removeChild(this.statsControls.dom)
      this.statsControls = null
    }
  }

  /** 添加天空盒子 */
  addSkyBox(skyType = 'night') {
    if (!this.skyboxs) this.skyboxs = new SkyBoxs(this)
    this.skyboxs.addSkybox(skyType)
    this.skyboxs.addFog()
  }

  /** 移除天空盒子 */
  removeSkybox() {
    if (this.skyboxs) {
      this.skyboxs.removeSkybox()
      this.skyboxs.removeFog()
    }
  }

  /** 更新天空盒 */
  updateSkyboxType(skyType = 'night') {
    if (this.skyboxs) this.skyboxs.updateSkyboxType(skyType)
  }

  /** 获取视图相机 */
  get viewCamera() {
    return this.camera
  }

  /** 更新环境光 */
  updateAmbientLight(color = 0xffffff, intensity = 1) {
    if (this.ambient) {
      this.scene.remove(this.ambient)
    }
    this.ambient = new AmbientLight(color, intensity)
    this.scene.add(this.ambient)
  }

  /** 更新平行光 */
  updateDirectionalLight(lights = []) {
    if (this.directionalLights && this.directionalLights.length) {
      for (const light in this.directionalLights) {
        this.scene.remove(light)
      }
    } else {
      this.directionalLights = []
    }
    for (const cLight in lights) {
      const light = new THREE.DirectionalLight(cLight.color)
      light.position.set(cLight.x, cLight.y, cLight.z)
      this.directionalLights.push(light)
      this.scene.add(light)
    }
  }

  /**注册鼠标事件监听 */
  initRaycaster() {
    this.raycaster = new Raycaster()

    const initRaycasterEvent = eventName => {
      const funWrap = throttle(event => {
        this.mouseEvent = event

        const canvas = document.getElementById('canvas') // 编辑模式画布
        let scale = 1.0 // 编辑模式画布缩放比例
        if (canvas) {
          const transform = canvas.style.transform || 'scale(1.0)'
          scale = parseFloat(transform.replace('scale(', '').replace(')', ''))
        }
        const cDomElement = document.getElementById('content')
        const px = cDomElement.getBoundingClientRect().left
        const py = cDomElement.getBoundingClientRect().top
        const pw = cDomElement.offsetWidth * scale
        const ph = cDomElement.offsetHeight * scale

        // console.log(`---->>> px:${px}, py:${py}, pw:${pw}, ph:${ph}`)

        // mouse.x = (<鼠标相对于可视区域的横坐标> / <可视区域的宽>) * 2 - 1;
        // mouse.y = -(<鼠标相对于可视区域的纵坐标> / <可视区域的高>) * 2 + 1;
        this.mouse.x = ((event.clientX - px) / pw) * 2 - 1
        this.mouse.y = -((event.clientY - py) / ph) * 2 + 1

        this.emitter.emit(
          Events[eventName].raycaster,
          this._getRaycasterIntersectObjects()
        )
      }, 50)
      this.viewerDom.addEventListener(eventName, funWrap, false)
    }

    initRaycasterEvent('click')
    initRaycasterEvent('dblclick')
    initRaycasterEvent('mousemove')
  }

  /** 销毁场景 */
  destroy() {
    this.scene.traverse(child => {
      if (child.material) child.material.dispose()
      if (child.geometry) child.geometry.dispose()
      child = null
    })
    this.renderer.forceContextLoss()
    this.renderer.dispose()
    this.scene.clear()

    this.isDestroy = true
  }

  _statsUpdate(statsControls) {
    statsControls.update()
  }

  _initViewer() {
    this.emitter = mitt()

    this._initRenderer()
    this._initScene()
    this._initLight()
    this._initCamera()
    this._initControl()

    this.raycaster = new Raycaster()
    this.mouse = new Vector2()

    const animate = () => {
      if (this.isDestroy) return
      requestAnimationFrame(animate)

      this._updateDom()
      this._readerDom()

      // 全局的公共动画函数，添加函数可同步执行
      this.animateEventList.forEach(event => {
        // event.fun && event.content && event.fun(event.content);
        if (event.fun && event.content) {
          event.fun(event.content)
        }
      })
    }

    animate()
  }

  _initScene() {
    this.scene = new Scene()
  }

  _initCamera() {
    // 渲染相机
    const aspect = window.innerWidth / window.innerHeight
    this.camera = new PerspectiveCamera(25, aspect, 1, 2000)
    //设置相机位置
    this.camera.position.set(4, 2, -3)
    //设置相机方向
    this.camera.lookAt(0, 0, 0)
  }

  _initRenderer() {
    // 获取画布dom
    this.viewerDom = document.getElementById(this.id)
    // 初始化渲染器
    this.renderer = new WebGLRenderer({
      logarithmicDepthBuffer: true,
      antialias: true, // true/false表示是否开启反锯齿
      alpha: true, // true/false 表示是否可以设置背景色透明
      precision: 'mediump', // highp/mediump/lowp 表示着色精度选择
      premultipliedAlpha: true // true/false 表示是否可以设置像素深度（用来度量图像的分辨率）
      // preserveDrawingBuffer: false, // true/false 表示是否保存绘图缓冲
      // physicallyCorrectLights: true // true/false 表示是否开启物理光照
    })
    this.renderer.clearDepth()

    this.renderer.shadowMap.enabled = true
    this.renderer.outputColorSpace = SRGBColorSpace // 可以看到更亮的材质，同时这也影响到环境贴图。
    this.viewerDom.appendChild(this.renderer.domElement)
  }

  _initControl() {
    this.controls = new OrbitControls(this.camera, this.renderer?.domElement)
    this.controls.enableDamping = false
    this.controls.screenSpacePanning = false // 定义平移时如何平移相机的位置 控制不上下移动
    this.controls.minDistance = 2
    this.controls.maxDistance = 1000
    this.controls.addEventListener('change', () => {
      this.renderer.render(this.scene, this.camera)
    })
  }

  _initLight() {
    this.ambient = new AmbientLight(0xffffff, 1)
    this.scene.add(this.ambient)

    const light = new THREE.DirectionalLight(0xffffff)
    light.position.set(0, 200, 100)
    light.castShadow = true
    light.shadow.camera.top = 180
    light.shadow.camera.bottom = -100
    light.shadow.camera.left = -120
    light.shadow.camera.right = 400
    light.shadow.camera.near = 0.1
    light.shadow.camera.far = 400
    // 设置mapSize属性可以使阴影更清晰，不那么模糊
    light.shadow.mapSize.set(1024, 1024)
    this.scene.add(light)
  }

  /** 渲染 DOM */
  _readerDom() {
    this.renderer?.render(this.scene, this.camera)
  }

  /** 更新 DOM */
  _updateDom() {
    this.controls.update()
    // 更新参数
    this.camera.aspect =
      this.viewerDom.clientWidth / this.viewerDom.clientHeight // 摄像机视锥体的长宽比，通常是使用画布的宽/画布的高
    this.camera.updateProjectionMatrix() // 更新摄像机投影矩阵。在任何参数被改变以后必须被调用,来使得这些改变生效
    this.renderer.setSize(
      this.viewerDom.clientWidth,
      this.viewerDom.clientHeight
    )
    this.renderer.setPixelRatio(window.devicePixelRatio) // 设置设备像素比
  }

  /**自定义鼠标事件触发的范围，给定一个模型组，对给定的模型组鼠标事件才生效 */
  setRaycasterObjects(objList) {
    this.raycasterObjects = objList
  }

  _getRaycasterIntersectObjects() {
    if (!this.raycasterObjects.length) return []
    this.raycaster.setFromCamera(this.mouse, this.camera)
    return this.raycaster.intersectObjects(this.raycasterObjects, true)
  }
}
